<template>
  <div class="mt-3 mb-3">
    <h5>Choose packaging</h5>
    <b-row class="w-100">
      <!-- Packaging products -->
      <!--<ProductCard
        v-for="(uid, indexPackage) in allPackagingCodes"
        :key="indexPackage"
        :product="uid"
        :idToken="user.idToken"
        :packaging="true"
        :isPositioned="false"
      />-->
    </b-row>
    <div class="d-flex flex-row">
      <ThumbnailCheckbox
        title="Flowpack"
        subTitle="1pc"
        class="mr-3"
        @change="
          propChange('packaging', 'flow1pc');
          selectPack(allPackagingCodes[0]);
        "
        v-model="selectedProperties.packaging.flow1pc"
      >
        <img
          src="@/assets/popdotconfig/flow1pc.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
      <ThumbnailCheckbox
        title="Flowpack"
        subTitle="2pcs"
        class="mr-3"
        @change="
          propChange('packaging', 'flow2pc');
          selectPack(allPackagingCodes[1]);
        "
        v-model="selectedProperties.packaging.flow2pc"
      >
        <img
          src="@/assets/popdotconfig/flow2pc.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
      <ThumbnailCheckbox
        title="Flowpack"
        subTitle="3pcs"
        class="mr-3"
        @change="
          propChange('packaging', 'flow3pc');
          selectPack(allPackagingCodes[2]);
        "
        v-model="selectedProperties.packaging.flow3pc"
      >
        <img
          src="@/assets/popdotconfig/flow3pc.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
    </div>
    <div class="d-flex flex-row">
      <ThumbnailCheckbox
        title="Flowpack"
        subTitle="chain"
        class="mr-3"
        @change="
          propChange('packaging', 'flowChain');
          selectPack(allPackagingCodes[3]);
        "
        v-model="selectedProperties.packaging.flowChain"
      >
        <img
          src="@/assets/popdotconfig/flowKetting.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
      <ThumbnailCheckbox
        title="Block Bottom Bag Clipped"
        subTitle="5pcs"
        class="mr-3"
        @change="
          propChange('packaging', 'clip5pc');
          selectPack(allPackagingCodes[7]);
        "
        v-model="selectedProperties.packaging.clip5pc"
      >
        <img
          src="@/assets/popdotconfig/clip5pc.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
      <ThumbnailCheckbox
        title="Block Bottom Bag Clipped"
        subTitle="8pcs"
        class="mr-3"
        @change="
          propChange('packaging', 'clip8pc');
          selectPack(allPackagingCodes[8]);
        "
        v-model="selectedProperties.packaging.clip8pc"
      >
        <img
          src="@/assets/popdotconfig/clip8pc.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
    </div>
    <div class="d-flex flex-row">
      <ThumbnailCheckbox
        title="Block Bottom Bag"
        subTitle="5pcs"
        class="mr-3"
        @change="
          propChange('packaging', 'box5pc');
          selectPack(allPackagingCodes[9]);
        "
        v-model="selectedProperties.packaging.box5pc"
      >
        <img
          src="@/assets/popdotconfig/blockbag-5pc.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
      <ThumbnailCheckbox
        title="Block Bottom Bag"
        subTitle="8pcs"
        class="mr-3"
        @change="
          propChange('packaging', 'box8pc');
          selectPack(allPackagingCodes[10]);
        "
        v-model="selectedProperties.packaging.box8pc"
      >
        <img
          src="@/assets/popdotconfig/blockbag-8pc.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
      <ThumbnailCheckbox
        title="Luxury box"
        subTitle="6pcs"
        class="mr-3"
        @change="
          propChange('packaging', 'LuxuryBox');
          selectPack(allPackagingCodes[4]);
        "
        v-model="selectedProperties.packaging.LuxuryBox"
      >
        <img
          src="@/assets/popdotconfig/long-box.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
    </div>
    <div class="d-flex flex-row">
      <ThumbnailCheckbox
        title="Tray"
        subTitle="6pcs"
        class="mr-3"
        @change="
          propChange('packaging', 'Tray');
          selectPack(allPackagingCodes[5]);
        "
        v-model="selectedProperties.packaging.Tray"
      >
        <img
          src="@/assets/popdotconfig/wrapped-box.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
      <ThumbnailCheckbox
        title="Bucket"
        subTitle="10pcs"
        class="mr-3"
        @change="
          propChange('packaging', 'Bucket');
          selectPack(allPackagingCodes[6]);
        "
        v-model="selectedProperties.packaging.Bucket"
      >
        <img
          src="@/assets/popdotconfig/bucket.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
      <ThumbnailCheckbox
        title="Bulk"
        subTitle="40pcs"
        class="mr-3"
        @change="
          propChange('packaging', 'bulkBrown');
          selectPack(allPackagingCodes[11]).then(() => {
            select(allProductCodes[0]).then(() => {
              propChange('popdots', 'choco', true);
            });
          });
        "
        v-model="selectedProperties.packaging.bulkBrown"
      >
        <img
          src="@/assets/popdotconfig/bulk1.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
    </div>
    <!-- Popdots -->
    <h5 class="mt-3">Choose product</h5>
    <div class="d-flex flex-row">
      <ThumbnailCheckbox
        title="Dark Popdots with Hazelnut"
        subTitle="Chocolate filling"
        class="mr-3"
        :isProduct="packageLoaded"
        @removePopdot="selectPack(selectedPackaging)"
        @change="
          select(allProductCodes[0]).then(() => {
            propChange('popdots', 'choco', false);
          })
        "
        v-model="selectedProperties.popdots.choco"
      >
        <img
          src="@/assets/popdotconfig/darkPopdotsWithHazelnut.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
      <ThumbnailCheckbox
        title="White popdots with caramel"
        subTitle="Custard filling"
        class="mr-3"
        :isProduct="packageLoaded"
        @removePopdot="selectPack(selectedPackaging)"
        @change="
          select(allProductCodes[1]).then(() => {
            propChange('popdots', 'white', false);
          })
        "
        v-model="selectedProperties.popdots.white"
      >
        <img
          src="@/assets/popdotconfig/whitePopdotsWithCaramel.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
      <ThumbnailCheckbox
        title="Pink Popdots with sprinkles"
        subTitle="Red fruit filling"
        class="mr-3"
        :isProduct="packageLoaded"
        @removePopdot="selectPack(selectedPackaging)"
        @change="
          select(allProductCodes[2]).then(() => {
            propChange('popdots', 'pink', false);
          })
        "
        v-model="selectedProperties.popdots.pink"
      >
        <img
          src="@/assets/popdotconfig/pinkPopdots.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
    </div>
    <!-- button with "customize popdot" -->
    <div class="d-flex flex-row">
      <ThumbnailCheckbox
        title="Custom"
        class="mr-3"
        :subTitle="customizedPopdot ? 'Add' : 'Create'"
        :isProduct="packageLoaded"
        @removePopdot="selectPack(selectedPackaging)"
        @change="
          //propChange('popdots', 'custom');
          selectCustom(customizedPopdot)
        "
        v-model="selectedProperties.popdots.custom"
      >
        <img
          src="@/assets/popdotconfig/customize.png"
          class="img-fluid thumbnail"
          draggable="false"
        />
      </ThumbnailCheckbox>
      <ThumbnailCheckbox :spacer="true"></ThumbnailCheckbox>
      <ThumbnailCheckbox :spacer="true"></ThumbnailCheckbox>
    </div>
    <!-- Generate Package -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ThumbnailCheckbox from "@/components/Inputs/ThumbnailCheckbox.vue";

export default {
  name: "PopdotPackageConfigTab",
  components: { ThumbnailCheckbox },
  props: {
    productData: Object,
  },
  data() {
    return {
      title: "",
      description: "",
      selectedPackaging: undefined,
      selectedProperties: {
        popdots: {
          choco: false,
          white: false,
          pink: false,
          custom: false,
        },
        packaging: {
          flow1pc: false,
          flow2pc: false,
          flow3pc: false,
          flowChain: false,
          LuxuryBox: false,
          Tray: false,
          Bucket: false,
          clip5pc: false,
          clip8pc: false,
          box5pc: false,
          box8pc: false,
          bulkBrown: false,
          bulkWhite: false,
          bulkPink: false,
          bulkCustom: false,
        },
        mono: false,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.load();
  },
  methods: {
    load: async function () {
      this.propChange("packaging", "flow1pc");
      this.selectPack(this.allPackagingCodes[0]);
    },
    reset: function () {
      for (const key of Object.entries(this.selectedProperties)) {
        if (key[1] === true) {
          this.selectedProperties[key[0]][1] = false;
        } else {
          for (const subkey of Object.entries(key[1])) {
            this.selectedProperties[key[0]][subkey[0]] = false;
          }
        }
      }
      this.$store.commit("packageLoaded", false);
      this.selectedPackaging = null;
      this.selectedProperties.mono = false;
    },
    propChange: function (category, property, toggleSiblings = true) {
      for (const key in this.selectedProperties[category]) {
        if (toggleSiblings || this.mono || this.selectedPackaging == null) {
          this.selectedProperties[category][key] = key === property;
        } else if (key === property) {
          this.selectedProperties[category][key] = true;
        }
        if (category === "coating") this.selectedCategory = property;
      }
      //load product choices which will be a matrix.
    },
    select: async function (productToLoad) {
      return new Promise((resolve, reject) => {
        this.$parent.$parent.$parent.$parent
          .loadNewPopdotAtPosition(productToLoad, this.packageLoaded)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    selectPack: function (productToLoad) {
      return new Promise((resolve, reject) => {
        if (productToLoad !== undefined) {
          this.$store.commit("packageLoaded", true);
          this.$parent.$parent.$parent.$parent
            .loadNewProduct(productToLoad)
            .then((mono) => {
              this.mono = mono;
              this.selectedPackaging = productToLoad;
              this.resetProperty("popdots");
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
    selectCustom: function (productToLoad) {
      if (productToLoad !== undefined) {
        this.select(productToLoad).then(() => {
          this.propChange("popdots", "custom", false);
        });
      } else {
        this.$store.commit("sidebarActiveTab", 1);
        //this.$parent.$parent.$parent.changeTab(1);
        this.$parent.$parent.changeTab(1);
      }
    },
    resetProperty: function (category) {
      for (const key in this.selectedProperties[category]) {
        this.selectedProperties[category][key] = false;
      }
    },
  },
  computed: {
    ...mapGetters([
      "appConfig",
      "user",
      "allProductCodes",
      "allPackagingCodes",
      "packageLoaded",
      "customizedPopdot",
    ]),
  },
  watch: {},
};
</script>

<style lang="scss" scoped></style>
