var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3 mb-3"},[_c('h5',[_vm._v("Choose packaging")]),_c('b-row',{staticClass:"w-100"}),_c('div',{staticClass:"d-flex flex-row"},[_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"Flowpack","subTitle":"1pc"},on:{"change":function($event){_vm.propChange('packaging', 'flow1pc');
        _vm.selectPack(_vm.allPackagingCodes[0]);}},model:{value:(_vm.selectedProperties.packaging.flow1pc),callback:function ($$v) {_vm.$set(_vm.selectedProperties.packaging, "flow1pc", $$v)},expression:"selectedProperties.packaging.flow1pc"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/flow1pc.png"),"draggable":"false"}})]),_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"Flowpack","subTitle":"2pcs"},on:{"change":function($event){_vm.propChange('packaging', 'flow2pc');
        _vm.selectPack(_vm.allPackagingCodes[1]);}},model:{value:(_vm.selectedProperties.packaging.flow2pc),callback:function ($$v) {_vm.$set(_vm.selectedProperties.packaging, "flow2pc", $$v)},expression:"selectedProperties.packaging.flow2pc"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/flow2pc.png"),"draggable":"false"}})]),_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"Flowpack","subTitle":"3pcs"},on:{"change":function($event){_vm.propChange('packaging', 'flow3pc');
        _vm.selectPack(_vm.allPackagingCodes[2]);}},model:{value:(_vm.selectedProperties.packaging.flow3pc),callback:function ($$v) {_vm.$set(_vm.selectedProperties.packaging, "flow3pc", $$v)},expression:"selectedProperties.packaging.flow3pc"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/flow3pc.png"),"draggable":"false"}})])],1),_c('div',{staticClass:"d-flex flex-row"},[_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"Flowpack","subTitle":"chain"},on:{"change":function($event){_vm.propChange('packaging', 'flowChain');
        _vm.selectPack(_vm.allPackagingCodes[3]);}},model:{value:(_vm.selectedProperties.packaging.flowChain),callback:function ($$v) {_vm.$set(_vm.selectedProperties.packaging, "flowChain", $$v)},expression:"selectedProperties.packaging.flowChain"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/flowKetting.png"),"draggable":"false"}})]),_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"Block Bottom Bag Clipped","subTitle":"5pcs"},on:{"change":function($event){_vm.propChange('packaging', 'clip5pc');
        _vm.selectPack(_vm.allPackagingCodes[7]);}},model:{value:(_vm.selectedProperties.packaging.clip5pc),callback:function ($$v) {_vm.$set(_vm.selectedProperties.packaging, "clip5pc", $$v)},expression:"selectedProperties.packaging.clip5pc"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/clip5pc.png"),"draggable":"false"}})]),_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"Block Bottom Bag Clipped","subTitle":"8pcs"},on:{"change":function($event){_vm.propChange('packaging', 'clip8pc');
        _vm.selectPack(_vm.allPackagingCodes[8]);}},model:{value:(_vm.selectedProperties.packaging.clip8pc),callback:function ($$v) {_vm.$set(_vm.selectedProperties.packaging, "clip8pc", $$v)},expression:"selectedProperties.packaging.clip8pc"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/clip8pc.png"),"draggable":"false"}})])],1),_c('div',{staticClass:"d-flex flex-row"},[_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"Block Bottom Bag","subTitle":"5pcs"},on:{"change":function($event){_vm.propChange('packaging', 'box5pc');
        _vm.selectPack(_vm.allPackagingCodes[9]);}},model:{value:(_vm.selectedProperties.packaging.box5pc),callback:function ($$v) {_vm.$set(_vm.selectedProperties.packaging, "box5pc", $$v)},expression:"selectedProperties.packaging.box5pc"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/blockbag-5pc.png"),"draggable":"false"}})]),_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"Block Bottom Bag","subTitle":"8pcs"},on:{"change":function($event){_vm.propChange('packaging', 'box8pc');
        _vm.selectPack(_vm.allPackagingCodes[10]);}},model:{value:(_vm.selectedProperties.packaging.box8pc),callback:function ($$v) {_vm.$set(_vm.selectedProperties.packaging, "box8pc", $$v)},expression:"selectedProperties.packaging.box8pc"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/blockbag-8pc.png"),"draggable":"false"}})]),_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"Luxury box","subTitle":"6pcs"},on:{"change":function($event){_vm.propChange('packaging', 'LuxuryBox');
        _vm.selectPack(_vm.allPackagingCodes[4]);}},model:{value:(_vm.selectedProperties.packaging.LuxuryBox),callback:function ($$v) {_vm.$set(_vm.selectedProperties.packaging, "LuxuryBox", $$v)},expression:"selectedProperties.packaging.LuxuryBox"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/long-box.png"),"draggable":"false"}})])],1),_c('div',{staticClass:"d-flex flex-row"},[_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"Tray","subTitle":"6pcs"},on:{"change":function($event){_vm.propChange('packaging', 'Tray');
        _vm.selectPack(_vm.allPackagingCodes[5]);}},model:{value:(_vm.selectedProperties.packaging.Tray),callback:function ($$v) {_vm.$set(_vm.selectedProperties.packaging, "Tray", $$v)},expression:"selectedProperties.packaging.Tray"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/wrapped-box.png"),"draggable":"false"}})]),_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"Bucket","subTitle":"10pcs"},on:{"change":function($event){_vm.propChange('packaging', 'Bucket');
        _vm.selectPack(_vm.allPackagingCodes[6]);}},model:{value:(_vm.selectedProperties.packaging.Bucket),callback:function ($$v) {_vm.$set(_vm.selectedProperties.packaging, "Bucket", $$v)},expression:"selectedProperties.packaging.Bucket"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/bucket.png"),"draggable":"false"}})]),_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"Bulk","subTitle":"40pcs"},on:{"change":function($event){_vm.propChange('packaging', 'bulkBrown');
        _vm.selectPack(_vm.allPackagingCodes[11]).then(function () {
          _vm.select(_vm.allProductCodes[0]).then(function () {
            _vm.propChange('popdots', 'choco', true);
          });
        });}},model:{value:(_vm.selectedProperties.packaging.bulkBrown),callback:function ($$v) {_vm.$set(_vm.selectedProperties.packaging, "bulkBrown", $$v)},expression:"selectedProperties.packaging.bulkBrown"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/bulk1.png"),"draggable":"false"}})])],1),_c('h5',{staticClass:"mt-3"},[_vm._v("Choose product")]),_c('div',{staticClass:"d-flex flex-row"},[_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"Dark Popdots with Hazelnut","subTitle":"Chocolate filling","isProduct":_vm.packageLoaded},on:{"removePopdot":function($event){return _vm.selectPack(_vm.selectedPackaging)},"change":function($event){_vm.select(_vm.allProductCodes[0]).then(function () {
          _vm.propChange('popdots', 'choco', false);
        })}},model:{value:(_vm.selectedProperties.popdots.choco),callback:function ($$v) {_vm.$set(_vm.selectedProperties.popdots, "choco", $$v)},expression:"selectedProperties.popdots.choco"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/darkPopdotsWithHazelnut.png"),"draggable":"false"}})]),_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"White popdots with caramel","subTitle":"Custard filling","isProduct":_vm.packageLoaded},on:{"removePopdot":function($event){return _vm.selectPack(_vm.selectedPackaging)},"change":function($event){_vm.select(_vm.allProductCodes[1]).then(function () {
          _vm.propChange('popdots', 'white', false);
        })}},model:{value:(_vm.selectedProperties.popdots.white),callback:function ($$v) {_vm.$set(_vm.selectedProperties.popdots, "white", $$v)},expression:"selectedProperties.popdots.white"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/whitePopdotsWithCaramel.png"),"draggable":"false"}})]),_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"Pink Popdots with sprinkles","subTitle":"Red fruit filling","isProduct":_vm.packageLoaded},on:{"removePopdot":function($event){return _vm.selectPack(_vm.selectedPackaging)},"change":function($event){_vm.select(_vm.allProductCodes[2]).then(function () {
          _vm.propChange('popdots', 'pink', false);
        })}},model:{value:(_vm.selectedProperties.popdots.pink),callback:function ($$v) {_vm.$set(_vm.selectedProperties.popdots, "pink", $$v)},expression:"selectedProperties.popdots.pink"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/pinkPopdots.png"),"draggable":"false"}})])],1),_c('div',{staticClass:"d-flex flex-row"},[_c('ThumbnailCheckbox',{staticClass:"mr-3",attrs:{"title":"Custom","subTitle":_vm.customizedPopdot ? 'Add' : 'Create',"isProduct":_vm.packageLoaded},on:{"removePopdot":function($event){return _vm.selectPack(_vm.selectedPackaging)},"change":function($event){//propChange('popdots', 'custom');
        _vm.selectCustom(_vm.customizedPopdot)}},model:{value:(_vm.selectedProperties.popdots.custom),callback:function ($$v) {_vm.$set(_vm.selectedProperties.popdots, "custom", $$v)},expression:"selectedProperties.popdots.custom"}},[_c('img',{staticClass:"img-fluid thumbnail",attrs:{"src":require("@/assets/popdotconfig/customize.png"),"draggable":"false"}})]),_c('ThumbnailCheckbox',{attrs:{"spacer":true}}),_c('ThumbnailCheckbox',{attrs:{"spacer":true}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }